.Keyboard {
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  &_Row {
    display: flex;
  }
  &_Key {
    cursor: default;
    font-size: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #dedede;
    &:hover {
      background: #efefef;
      overflow: visible;
      font-size: 16px;
      .Keyboard_KeyInfo {
        background: #efefef;
        position: relative;
        width: 220px;
        width: max-content;
        max-width: 220px;
        font-size: 14px;
        color: #000;
        padding: 12px;
      }
    }
  }
  &_KeyName {
    display: inline-block;
    padding-left: 8px;
    padding-top: 8px;
    &-Shift {
      position: relative;
      padding: 0;
      margin-left: 2px;
      top: 2px;
      color: #999;
    }
  }
  &_KeyInfo {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 1.2;
    padding: 4px;
    color: #555;
  }
}

.KeyInfo {
  &_Info {
    > * {
      display: inline;
    }
    &ModKey {
      font-size: 0;
      display: inline-block;
      &::first-letter {
        font-size: 12px;
        color: rgb(230, 1, 161);
        font-weight: bold;
      }
    }
    &Category {
      display: none;
      font-size: 12px;
      color: rgb(63, 79, 224);
      padding: 0 2px;
    }
    &Tip {
      display: none;
      color: rgb(17, 146, 5);
      padding: 0 2px;
    }
    .Keyboard_Key:hover & {
      &Category,
      &Tip {
        display: inline;
      }
      &ModKey {
        font-size: 12px;
        padding-right: 4px;
        display: inline;
        color: rgb(230, 1, 161);
      }
    }
  }
}

.KeyboardTable {
  margin-top: 16px;
  tr:hover {
    background-color: #efefef;
  }
}

.Keyboard-Qwerty {
  $gap-width: 6px;
  $gap-height: 4px;
  $key-unit: 6px;
  $key-width-factor: 10;
  $key-height-factor: 14;

  $area-gap-a: 8px; // 主键盘和功能键盘
  $area-gap-b: 8px; // 功能键盘和数字键盘
  $area-gap-c: 12px; // Fn 和主键盘

  $key-width: $key-unit * $key-width-factor;
  $key-height: $key-unit * $key-height-factor;

  gap: $gap-height;
  .Keyboard_Row {
    gap: $gap-width;
    height: $key-height;
  }
  .Keyboard_Key {
    width: $key-width;
    height: $key-height;
  }

  [data-row='0'] [data-pos='13'],
  [data-row='1'] [data-pos='14'],
  [data-row='2'] [data-pos='14'],
  [data-row='5'] [data-pos='7'] {
    // 主键盘区和功能区间隔
    margin-left: $area-gap-a;
  }
  [data-row='1'] [data-pos='17'],
  [data-row='2'] [data-pos='17'],
  [data-row='5'] [data-pos='10'] {
    // 功能区和小键盘区间隔
    margin-left: $area-gap-b;
  }
  [data-row='2'] [data-pos='20'],
  [data-row='4'] [data-pos='16'] {
    // Num+, NumEnter
    height: $key-height * 2 + $gap-height;
    position: relative;
  }
  [data-row='0'] {
    [data-pos='1'] {
      // F1
      margin-left: $key-width + $gap-width;
    }
    [data-pos='5'],
    [data-pos='9'] {
      // F5, F9
      margin-left: ($key-width * 0.5) + ($gap-width * 0.5);
    }
  }
  [data-row='1'] {
    margin-top: $area-gap-c;
    [data-pos='13'] {
      // Backspace
      width: $key-width * 2 + $gap-width;
    }
  }
  [data-row='2'] {
    [data-pos='0'] {
      // Tab
      width: $key-width * 1.5;
    }
    [data-pos='13'] {
      // \|
      width: $key-width * 1.5 + $gap-width;
    }
  }
  [data-row='3'] {
    [data-pos='0'] {
      // Caps
      width: $key-width + ($key-unit * 6);
    }
    [data-pos='12'] {
      // Enter
      width: ($key-width * 2) + ($key-unit * ($key-width-factor - 6)) +
        $gap-width * 2;
    }
    [data-pos='13'] {
      // Num4
      margin-left: $area-gap-a + $area-gap-b + ($key-width * 3) +
        ($gap-width * 3);
    }
  }
  [data-row='4'] {
    [data-pos='0'] {
      // ShiftL
      width: $key-width * 2 + ($gap-width * 3);
    }
    [data-pos='11'] {
      // ShiftR
      width: $key-width * 3;
    }
    [data-pos='12'] {
      // Up
      margin-left: $area-gap-a + $key-width + $gap-width;
    }
    [data-pos='13'] {
      // Num1
      margin-left: $area-gap-b + $key-width + $gap-width;
    }
  }
  [data-row='5'] {
    [data-pos='0'],
    [data-pos='1'],
    [data-pos='2'],
    [data-pos='4'],
    [data-pos='5'],
    [data-pos='6'] {
      // Ctrl, Win, Alt
      width: $key-width * 1.5;
    }
    [data-pos='3'] {
      // Space
      width: $key-width * 6 + $gap-width * 8;
    }
    [data-pos='10'] {
      // Num0
      width: $key-width * 2 + $gap-width;
    }
  }
}
